import { APPCONFIG } from "../../../../../../app.config"
import { ALERT_TYPE } from "../../../../../../consts/alertType"
import encrypt from "../../../../../../utils/encrypt"
import { onRequestError, standardRequest } from "../../../../../../utils/requests"
import ShowToastMessage from "../../../../../../utils/toast"
import { validatePassword } from "../../../../../../utils/validator"
import { InputSecret } from "../../../../../common"
import { useForm, useManagedContext, useNav } from "../../../../../hooks"

const ChangePasswordModal = ({ close, username }) => {
    const [formValues, formHandle] = useForm({ passwordActual: '', passwordNueva: '', passwordRepeat: '',})

    const { setIsLoading } = useManagedContext()
    const navigate = useNav()

    const onAccept = () => {
        if (formValues.passwordNueva === '')
            return ShowToastMessage(ALERT_TYPE.ALERT_WARNING, 'Debe ingresar una contraseña')

        const validation = validatePassword(formValues.passwordNueva)
        if (!validation.success)
            return ShowToastMessage(ALERT_TYPE.ALERT_WARNING, validation.message)

        if (formValues.passwordRepeat === '')
            return ShowToastMessage(ALERT_TYPE.ALERT_WARNING, 'Debe repetir la contraseña')
        if (formValues.passwordNueva !== formValues.passwordRepeat)
            return ShowToastMessage(ALERT_TYPE.ALERT_WARNING, 'Las contraseñas deben coincidir')

        setIsLoading(true)
        standardRequest(
            'POST',
            `${APPCONFIG.SITE.WEBAPI}api/usuario/replace-password`,
            {
                username,
                oldPassword: encrypt(formValues.passwordActual),
                newPassword: encrypt(formValues.passwordNueva),
            },
        ).then(() => {
            ShowToastMessage(ALERT_TYPE.ALERT_SUCCESS, 'La contraseña fue cambiada correctamente', () => {
                setIsLoading(false)
                navigate({ to: '/logout', })
            })
        }).catch(error => {
            onRequestError(error)
            setIsLoading(false)
        })
    }

    return (
    <>

    <div className="modal modal-block" role="dialog" data-keyboard="false" data-backdrop="static" >
      <div className="modal-dialog">
        <div className="modal-content animated fadeIn">

          <div className="modal-header">
            <h2 className="modal-title">Cambio de contraseña</h2>
          </div>

          <div className="modal-body">
            <div className="row">

                <div className="mb-3 col-12">
                    <InputSecret
                        title="Contraseña Actual"
                        htmlFor="codigo"
                        type="password"
                        name="passwordActual"
                        value={formValues.passwordActual}
                        onChange={formHandle}
                    />
                </div>
                <div className="mb-3 col-12">
                    <InputSecret
                        title="Contraseña Nueva"
                        htmlFor="codigo"
                        type="password"
                        name="passwordNueva"
                        value={formValues.passwordNueva}
                        onChange={formHandle}
                    />
                </div>
                <div className="mb-3 col-12">
                    <InputSecret
                        title="Repetir Contraseña Nueva"
                        htmlFor="codigo"
                        type="password"
                        name="passwordRepeat"
                        value={formValues.passwordRepeat}
                        onChange={formHandle}
                    />
                </div>
            </div>
          </div>

          <div className="modal-footer">
                <button className="btn btn-outline-primary" data-dismiss="modal" onClick={close}>Cancelar</button>
                <button className="btn btn-primary" data-dismiss="modal" onClick={onAccept}>Aceptar</button>
          </div>
          
        </div>
      </div>
    </div>

    </>

        
    )
}

export default ChangePasswordModal
