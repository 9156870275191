import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { InputSecret, Loading } from '../../components/common';
import { useForm, useNav} from '../../components/hooks';
import { REQUEST_METHOD } from '../../consts/requestMethodType';
import { APPCONFIG } from '../../app.config';
import ShowToastMessage from '../../utils/toast';
import { ALERT_TYPE } from '../../consts/alertType';
import encrypt from '../../utils/encrypt';
import { onRequestError, standardRequest } from '../../utils/requests'
import HeaderBasic from '../../components/layout/HeaderBasic';
import logo from '../../assets/images/LogoMunicipio.png';
import { validatePassword } from '../../utils/validator';

const ChangePasswordView = () => {
    const params = useParams()
    const navigate = useNav()

    const [formValues, formHandle] = useForm({ password: '', repeatPassword: '' })
    const [loading, setLoading] = useState(false)
    const [token, setToken] = useState(null)

    useEffect(() => {
        setLoading(true)

        standardRequest(REQUEST_METHOD.GET, `${APPCONFIG.SITE.WEBAPI}api/verificacion/token/${params.token}`)
        .then(data => setToken(data.token))
        .catch(e => {
            onRequestError(e)
            setToken(null)
        })
        .finally(() => setLoading(false))
    }, [])

    const onAccept = () => {
        if (formValues.password === '')
            return ShowToastMessage(ALERT_TYPE.ALERT_WARNING, 'Debe ingresar una contraseña')

        const validation = validatePassword(formValues.password)
        if (!validation.success)
            return ShowToastMessage(ALERT_TYPE.ALERT_WARNING, validation.message)

        if (formValues.repeatPassword === '')
            return ShowToastMessage(ALERT_TYPE.ALERT_WARNING, 'Debe repetir la contraseña')
        if (formValues.password !== formValues.repeatPassword)
            return ShowToastMessage(ALERT_TYPE.ALERT_WARNING, 'Las contraseñas deben coincidir')

        standardRequest(
            REQUEST_METHOD.POST,
            `${APPCONFIG.SITE.WEBAPI}api/usuario/recover-password`,
            { token, password: encrypt(formValues.password) },
            { useAuth: false, },
        ).then(data => {
            setToken(null)
            ShowToastMessage(ALERT_TYPE.ALERT_SUCCESS, 'La contraseña fue cambiada correctamente', () => {
                navigate({ to: '/login', replace: true, })
            })
        }).catch(onRequestError)
    }

    return (
        <div>
            <HeaderBasic showMenu={false} />
            <div className='bg-img'>
            
                <Loading visible={loading}></Loading>
                    
                <div className="login-container">
                    <section className='lg-c'>

                        <div className="mb-3">
                            <InputSecret
                                title="Contraseña"
                                name="password"
                                type="password"
                                placeholder=""
                                autoComplete="off"
                                value={ formValues.password }
                                onChange={ formHandle }
                                disabled={loading || token === null}
                            />
                        </div>
                        <div className="mb-3">
                            <InputSecret
                                title="Repetir contraseña"
                                name="repeatPassword"
                                type="password"
                                placeholder=""
                                autoComplete="off"
                                value={ formValues.repeatPassword }
                                onChange={ formHandle }
                                disabled={loading || token === null}
                            />
                        </div>
                        <div className="mb-3">
                            <button className="btn btn-main" onClick={onAccept} disabled={loading || token === null}>
                                Cambiar contraseña
                            </button>
                        </div>

                        <a href='#' className="login-link" onClick={() => navigate({ to: '/login' })}>Volver</a>
                    </section>
                </div>

            </div>
        </div>
    )
}

export default ChangePasswordView