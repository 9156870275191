import React, { useState, useEffect, useMemo } from 'react';

import { REQUEST_METHOD } from "../../consts/requestMethodType";
import { APIS } from '../../config/apis';
import { useForm } from '../../components/hooks/useForm';
import { ServerRequest } from '../../utils/apiweb';
import { ALERT_TYPE } from '../../consts/alertType';
import { MESSAGE_MODALS } from '../../context/custom/MessageModalContext';
import { Loading, TableCustom, SectionHeading, AdvancedSearch, InputWithEnterEvent } from '../../components/common';
import ShowToastMessage from '../../utils/toast';
import PerfilModal from '../../components/controls/PerfilModal';
import { useAccess, useNav, useManagedContext } from '../../components/hooks';


function PerfilesView() {

    //hooks
    const navigate = useNav()
    const { showMessageModal } = useManagedContext()

    const [state, setState] = useState({
        loading: false,
        showForm: false,
        modeFormEdit: false,
        rowId: 0,
        list: []
    });

    const [perfiles, setPerfiles] = useState([]);

    const [filters, setFilters] = useState({
        labels: [
            { title: 'Código', field: 'codigo', value: '', valueIgnore: ''},
            { title: 'Nombre', field: 'nombre', value: '', valueIgnore: '' }
        ]
    });

    const [ formValues, formHandle, , formSet ] = useForm({
        codigo: '',
        nombre: ''
    });

    const filteredData = useMemo(() => (
        state.list.filter(x => (
            (formValues.codigo.value === '' || x.codigo.toLowerCase().includes(formValues.codigo.toLowerCase()))
            && (formValues.nombre.value === '' || x.nombre.toLowerCase().includes(formValues.nombre.toLowerCase()))
        ))
    ), [filters, state.list])

    const [hasEditAccess, setHasEditAccess] = useState(false);
    useAccess({
        key: 'PerfilesView',
        onLoaded: (data, isSuccess, error) => {
            if (!isSuccess) ShowToastMessage(ALERT_TYPE.ALERT_ERROR, error)
            else setHasEditAccess(data.some(x => x.codigo === 'perfil_edit'))
        }
    });

    const mount = () => {

        SearchPerfiles();

        const unmount = () => {}
        return unmount;
    }
    useEffect(mount, []);

    //definiciones
    const cellA = (props) => !hasEditAccess ? '' : (
        <div className='action'>
            <div onClick={ (event) => handleClickPerfilAdd() } className="link">
                <span className="material-symbols-outlined" title="Nuevo">add</span>
            </div>
        </div>
    )
    const cellVMRB = (props) => (
        <div className='action'>
            <div onClick={ (event) => handleClickPerfilView(props.value) } className="link">
                <span className="material-symbols-outlined" title="Ver">search</span>
            </div>
            {hasEditAccess && <>
                <div onClick={ (event) => handleClickPerfilModify(props.value) } className="link">
                    <span className="material-symbols-outlined" title="Modificar">stylus</span>
                </div>
                <div onClick={ (event) => handleClickPerfilRemove(props.value) } className="link">
                    <span className="material-symbols-outlined" title="Eliminar">delete</span>
                </div>
                <div onClick={ (event) => handleClickPerfilBind(props.value) } className="link">
                <span className="material-symbols-outlined" title="Asignar permisos">link</span>
                </div>
            </>}
        </div>
    )
    const tableColumns = [
        { Header: 'Código', accessor: 'codigo'},
        { Header: 'Nombre', accessor: 'nombre'},
        { Header: cellA, Cell: cellVMRB, id:'abm', accessor: 'id', width: '155px', disableGlobalFilter: true, disableSortBy: true }
    ];

    //handles
    const handleClickPerfilAdd = () => {
        setState(prevState => {
            return {...prevState, showForm: true, modeFormEdit: true, rowId: 0};
        });
        setPerfiles(state.list.map(x => x.codigo.toLowerCase()));
    }
    const handleClickPerfilView = (id) => {
        setState(prevState => {
            return {...prevState, showForm: true, modeFormEdit: false, rowId: parseInt(id)};
        });
    }
    const handleClickPerfilModify = (id) => {
        setState(prevState => {
            return {...prevState, showForm: true, modeFormEdit: true, rowId: parseInt(id)};
        });
        setPerfiles(state.list.filter(x => x.id !== id).map(x => x.codigo.toLowerCase()));
    }
    const handleClickPerfilRemove = (id) => {
        const perfil = state.list.find(x => x.id === id)
        showMessageModal({
            ...MESSAGE_MODALS.BORRAR,
            message: `¿Está seguro de borrar el perfil ${perfil.nombre}?`,
            onConfirm: () => RemovePerfil(id),
        })
    }
    const handleClickPerfilBind = (id) => {
        navigate({ to: `/perfil/permisos/${id}` })
    }

    //callbacks
    const callbackNoSuccess = (response) => {
        response.json()
        .then((error) => {
          const message = error.message;
          ShowToastMessage(ALERT_TYPE.ALERT_ERROR, message);
          setState(prevState => {
            return {...prevState, loading: false};
          });
        })
        .catch((error) => {
            const message = 'Error procesando respuesta: ' + error;
            ShowToastMessage(ALERT_TYPE.ALERT_ERROR, message);
            setState(prevState => {
              return {...prevState, loading: false};
            });
        });
    }
    const callbackError = (error) => {
        const message = 'Error procesando solicitud: ' + error.message;
        ShowToastMessage(ALERT_TYPE.ALERT_ERROR, message);
        setState(prevState => {
          return {...prevState, loading: false};
        });
    }

    //funciones
    function SearchPerfiles() {

        setState(prevState => {
            return {...prevState, loading: true, list: []};
        });

        const callbackSuccess = (response) => {
            response.json()
            .then((data) => {
                setState(prevState => {
                    return {...prevState, loading: false, list: data};
                });
            })
            .catch((error) => {
                const message = 'Error procesando respuesta: ' + error;
                ShowToastMessage(ALERT_TYPE.ALERT_ERROR, message);
                setState(prevState => {
                    return {...prevState, loading: false};
                });
            });
        };

        ServerRequest(
            REQUEST_METHOD.GET,
            null,
            true,
            APIS.URLS.PERFIL,
            null,
            null,
            callbackSuccess,
            callbackNoSuccess,
            callbackError
        );

    }

    function RemovePerfil(id) {

        setState(prevState => {
            return {...prevState, loading: true};
        });

        const callbackSuccess = (response) => {
            response.json()
            .then((id) => {
                setState(prevState => {
                    return {...prevState, loading: false};
                });
                SearchPerfiles();
            })
            .catch((error) => {
                const message = 'Error procesando respuesta: ' + error;
                ShowToastMessage(ALERT_TYPE.ALERT_ERROR, message);
                setState(prevState => {
                    return {...prevState, loading: false};
                });
            });
        };

        const paramsUrl = `/${id}`;

        ServerRequest(
            REQUEST_METHOD.DELETE,
            null,
            true,
            APIS.URLS.PERFIL,
            paramsUrl,
            null,
            callbackSuccess,
            callbackNoSuccess,
            callbackError
        );

    }

    function ApplyFilters() {
        UpdateFilters();
        SearchPerfiles();
    }

    function UpdateFilters() {
        let labels = [...filters.labels];
        labels.forEach((label) => {
            label.value = formValues[label.field];
        });
        setFilters(prevState => {
            return {...prevState, labels: labels};
        });
    }

    return (
    <>

        <Loading visible={state.loading}></Loading>

        {state.showForm && 
            <PerfilModal
                id={state.rowId}
                disabled={!state.modeFormEdit}
                data={{
                    listPerfiles: perfiles
                }}
                onDismiss={() => {
                    setState(prevState => {
                        return {...prevState, showForm: false, rowId: 0};
                    });
                }}
                onConfirm={(id) => {
                    setState(prevState => {
                        return {...prevState, showForm: false, rowId: 0};
                    });
                    SearchPerfiles();
                }}
            />
        }

        <SectionHeading titles={['Administración de perfiles']} />
    
        <section className='section-accordion'>

        <AdvancedSearch
            formValues={formValues}
            formSet={formSet}
            labels={filters.labels.filter(f => f.value !== f.valueIgnore)}
            onSearch={ApplyFilters}
        >
                <div className='row form-basic'>
                    <div className="col-12 col-md-6">
                        <label htmlFor="codigo" className="form-label">Código</label>
                        <InputWithEnterEvent
                            name="codigo"
                            type="text"
                            placeholder=""
                            className="form-control"
                            value={formValues.codigo}
                            onChange={ formHandle }
                            onEnter={ApplyFilters}
                        />
                    </div>
                    <div className="col-12 col-md-6">
                        <label htmlFor="nombre" className="form-label">Nombre</label>
                        <InputWithEnterEvent
                            name="nombre"
                            type="text"
                            placeholder=""
                            className="form-control"
                            value={formValues.nombre}
                            onChange={ formHandle }
                            onEnter={ApplyFilters}
                        />
                    </div>
                </div>
            </AdvancedSearch>

            <div className="m-top-20">

                <TableCustom
                    className={'TableCustomBase'}
                    columns={tableColumns}
                    data={filteredData}
                />

            </div>

        </section>

    </>
    )
}

export default PerfilesView;
